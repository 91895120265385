<template>
    <div class="py-4">
        <div class="row for_desktop">
            <div class="col-md-12 px-0">
                <h4 class="mb-4 text-center font-active">Moments From Around The World</h4>
                <div class="grid_flex">
                    <div class="grid_box">
                        <NuxtLink :to="localePath('/testimonials/vatsal-roshni')">
                            <picture>
                                <source width="300" height="300" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/vatsal-roshni.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/vatsal-roshni.jpg" alt="vatsal-roshni Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">
                                    <h3>Vatsal & Roshni</h3>
                                    <h5>Tampa, FL</h5>
                                </div>
                            </div>
                        </NuxtLink>
                        <NuxtLink :to="localePath('/testimonials/amit-eleonora')">
                            <picture>
                                <source width="300" height="450" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/amit-eleonora.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="450" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/amit-eleonora.jpg" alt="amit-eleonora Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">
                                    <h3>Amit & Eleonora</h3>
                                    <h5>Dallas, TX</h5>
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                    <div class="grid_box">
                        <NuxtLink :to="localePath('/testimonials/frederick-jazmin')">
                            <picture>
                                <source width="300" height="450" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/frederick-jazmin.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="450" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/frederick-jazmin.jpg"
                                    alt="frederick-jazmin Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">
                                    <h3>Frederick & Jazmin</h3>
                                    <h5>Winnipeg, MB</h5>
                                </div>
                            </div>
                        </NuxtLink>
                        <NuxtLink :to="localePath('/testimonials/satya-vrinda')">
                            <picture>
                                <source width="300" height="300" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/satya-vrinda.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/satya-vrinda.jpg" alt="satya-vrinda Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">

                                    <h3>Satya & Vrinda</h3>
                                    <h5>Mumbai, India</h5>
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                    <div class="grid_box">
                        <NuxtLink :to="localePath('/testimonials/akshay-vidhi')">
                            <picture>
                                <source width="300" height="300" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/akshay-vidhi.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/akshay-vidhi.jpg" alt="akshay-vidhi Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">

                                    <h3>Akshay & Vidhi</h3>
                                    <h5>Hyderabad, India</h5>
                                </div>
                            </div>
                        </NuxtLink>
                        <NuxtLink :to="localePath('/testimonials/rohit-natasha')">
                            <picture>
                                <source width="300" height="450" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/rohit-natasha.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="450" type="image/jpeg" src="~/assets/home/testimonials_india/rohit-natasha.jpg" alt="rohit-natasha Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">

                                    <h3>Rohit & Natasha</h3>
                                    <h5>Germantown, MD</h5>
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                    <div class="grid_box_mod">
                        <div class="grid_box_mod_flex">
                            <NuxtLink :to="localePath('/testimonials/carlos-debbie')">
                                <picture>
                                    <source width="300" height="300" type="image/webp"
                                        srcset="~/assets/home/testimonials_india/carlos-debbie.webp">
                                    <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                        src="~/assets/home/testimonials_india/carlos-debbie.jpg"
                                        alt="carlos-debbie Couple">
                                </picture>
                                <div class="upper_text">
                                    <div class="">

                                        <h3>Carlos & Debbie</h3>
                                        <h5>Augusta, GA</h5>
                                    </div>
                                </div>
                            </NuxtLink>
                            <NuxtLink :to="localePath('/testimonials/peter-jenn')">
                                <picture>
                                    <source width="300" height="300" type="image/webp"
                                        srcset="~/assets/home/testimonials_india/peter-jenn.webp">
                                    <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                        src="~/assets/home/testimonials_india/peter-jenn.jpg" alt="peter-jenn Couple">
                                </picture>
                                <div class="upper_text">
                                    <div class="">

                                        <h3>Peter & Jenn</h3>
                                        <h5>Phoenix, AZ</h5>
                                    </div>
                                </div>
                            </NuxtLink>
                        </div>
                        <NuxtLink :to="localePath('/testimonials/rahul-vishakha')">
                            <picture>
                                <source width="617" height="449" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/rahul-vishakha.webp">
                                <img class="radious_eight" width="617" height="449" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/rahul-vishakha.jpg"
                                    alt="rahul-vishakha Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">

                                    <h3>Rahul & Vishakha</h3>
                                    <h5>Delhi, India</h5>
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </div>
        <div class="for_mobile mobile_test">
            <!-- :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
            }" -->
            <h4 class="mb-4 text-center font-active">Moments From Around The World</h4>
            <swiper ref="c1" :modules="[SwiperAutoplay]" :loop="true" :loopAdditionalSlides="0" :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
            }" :breakpoints="{
                355: {
                    slidesPerView: 1.5,
                    centeredSlides: true,
                    spaceBetween: 5,
                    slidesPerGroup: 1,
                    autoplay: {
                        delay: 2500,
                        disableOnInteraction: false
                    }
                },
                512: {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                    autoplay: {
                        delay: 2500,
                        disableOnInteraction: false
                    }
                },
                992: {
                    slidesPerView: 3,
                    slidesPerGroup: 1,
                },

            }" class="mySwiper">
                <swiper-slide>
                    <div class="slide-box">
                        <NuxtLink :to="localePath('/testimonials/vatsal-roshni')">
                            <picture>
                                <source width="300" height="300" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/vatsal-roshni.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/vatsal-roshni.jpg" alt="vatsal-roshni Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">

                                    <h3>Vatsal & Roshni</h3>
                                    <h5>Tampa, FL</h5>
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide-box">
                        <NuxtLink :to="localePath('/testimonials/amit-eleonora')">
                            <picture>
                                <source width="300" height="300" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/amit-eleonora.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/amit-eleonora.jpg" alt="amit-eleonora Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">

                                    <h3>Amit & Eleonora</h3>
                                    <h5>Dallas, TX</h5>
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide-box">
                        <NuxtLink :to="localePath('/testimonials/frederick-jazmin')">
                            <picture>
                                <source width="300" height="300" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/frederick-jazmin.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/frederick-jazmin.jpg"
                                    alt="frederick-jazmin Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">

                                    <h3>Frederick & Jazmin</h3>
                                    <h5>Winnipeg, MB</h5>
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide-box">
                        <NuxtLink :to="localePath('/testimonials/satya-vrinda')">
                            <picture>
                                <source width="300" height="300" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/satya-vrinda.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/satya-vrinda.jpg" alt="satya-vrinda Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">

                                    <h3>Satya & Vrinda</h3>
                                    <h5>Mumbai, India</h5>
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide-box">
                        <NuxtLink :to="localePath('/testimonials/akshay-vidhi')">
                            <picture>
                                <source width="300" height="300" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/akshay-vidhi.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/akshay-vidhi.jpg" alt="akshay-vidhi Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">

                                    <h3>Akshay & Vidhi</h3>
                                    <h5>Hyderabad, India</h5>
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide-box">
                        <NuxtLink :to="localePath('/testimonials/rohit-natasha')">
                            <picture>
                                <source width="300" height="300" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/rohit-natasha.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/rohit-natasha.jpg" alt="rohit-natasha Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">

                                    <h3>Rohit & Natasha</h3>
                                    <h5>Germantown, MD</h5>
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide-box">
                        <NuxtLink :to="localePath('/testimonials/carlos-debbie')">
                            <picture>
                                <source width="300" height="300" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/carlos-debbie.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/carlos-debbie.jpg" alt="carlos-debbie Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">

                                    <h3>Carlos & Debbie</h3>
                                    <h5>Augusta, GA</h5>
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide-box">
                        <NuxtLink :to="localePath('/testimonials/peter-jenn')">
                            <picture>
                                <source width="300" height="300" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/peter-jenn.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/peter-jenn.jpg" alt="peter-jenn Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">

                                    <h3>Peter & Jenn</h3>
                                    <h5>Phoenix, AZ</h5>
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="slide-box">
                        <NuxtLink :to="localePath('/testimonials/rahul-vishakha')">
                            <picture>
                                <source width="300" height="300" type="image/webp"
                                    srcset="~/assets/home/testimonials_india/rahul-vishakha.webp">
                                <img class="radious_eight" loading="lazy" width="300" height="300" type="image/jpeg"
                                    src="~/assets/home/testimonials_india/rahul-vishakha.jpg"
                                    alt="rahul-vishakha Couple">
                            </picture>
                            <div class="upper_text">
                                <div class="">
                                    <h3>Rahul & Vishakha</h3>
                                    <h5>Delhi, India</h5>
                                </div>
                            </div>
                        </NuxtLink>
                    </div>
                </swiper-slide>
            </swiper>
        </div>

        <div class="view_all_btn mt-4">
            <NuxtLink :to="localePath('/testimonials')">View All</NuxtLink>
        </div>
    </div>
</template>


<script setup>
const localePath = useLocalePath()

</script>

<style scoped>
.view_all_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.view_all_btn a {
    color: #000;
    display: inline-block;
    background: #fff !important;
    border: 1px solid #000;
    font-size: 13px !important;
    font-weight: 600;
    margin: 0 auto;
    padding: 9px 25px;
    text-transform: uppercase;
    min-width: 150px;
    text-align: center;
    border-radius: 4px;
}

.form-control {
    width: fit-content;
    height: auto;
    padding: 0;
    font-size: 18px;
    color: #fff;
    border: 0;
    margin-top: 6%;
    background: transparent;
}

.grid_flex a {
    color: #000;
    position: relative;
}

.grid_flex .upper_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.43137);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding: 5%;
    display: none;
    transition: 0.5s;
    border-radius: 8px;
}

.grid_flex a:hover .upper_text {
    display: flex;
    transition: 0.5s;
}

.grid_flex .upper_text h3 {
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    padding: 5% 0 2% 0;
}

.grid_flex .upper_text h5 {
    font-size: 17px;
    color: #dfdfdf;
}

h4 {
    font-size: 30px;
}

.grid_flex {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
}

.grid_box {
    flex: 20%;
    max-width: 20%;
    padding: 5px 8px;
}

.grid_box a,
.grid_box_mod_flex a {
    margin-bottom: 15px;
}

.grid_box_mod a,
.grid_box a {
    display: inline-block;
}

.grid_box_mod img,
.grid_box img {

    vertical-align: middle;
    max-width: 100%;
    height: auto;
    width: 100%;
}

.grid_box_mod {
    flex: 40%;
    max-width: 40%;
    padding: 5px 8px;
}

.grid_box_mod_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.grid_box_mod_flex a:first-child {
    margin-right: 15px;
}

@media(max-width: 1250px) {
    .grid_flex .upper_text h3 {
        font-size: 18px;
    }

    .grid_flex .upper_text h5 {
        font-size: 14px;
    }
}

@media(max-width: 767px) {
    h4 {
        font-size: 24px;
    }

    .mobile_test {
        width: 94%;
        margin: 0 auto;
    }

    .slide-box {
        text-align: center;
        padding: 2%;
    }

    .slide-box img {
        /* width: 300px; */
        height: 350px;
        object-fit: cover;
    }

    .slide-box h3 {
        font-size: 16px;
        margin-top: 4%;
    }

    .slide-box a {
        color: #000;
    }

    .slide-box h5 {
        font-size: 14px;
    }

    .form-control {
        color: #000;
        text-align: center;
        width: 40%;
        margin: 5% auto 3% auto;
        font-size: 16px;
    }
}
</style>